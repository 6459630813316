@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Changa:wght@500;600;700;800&display=swap');
.App {
  text-align: center;
}

/* css VERO*/
*{
  font-family: 'Roboto', sans-serif;
  font-weight:400;
}
.row{
  border:yellow;
  margin:0!important;
  padding:0!important;
}
section{border:blue;
  margin:0!important;
  padding:0!important;
}
/* SCROLLBAR CSS3 */
::-webkit-scrollbar{
  width: 10px;
  background: #dbe8ec;
}
::-webkit-scrollbar-button{
  width:8px;
  height: 5px;
}
::-webkit-scrollbar-track{
  background:#3c454e;
  border:thin solid #1a1f25;
  -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb{
  background: -webkit-linear-gradient(top, #d9f665, #a0ba38);
  -webkit-box-shadow:   inset 0 1px 0 rgba(255,255,225,.5),
  inset 1px 0 0 rgba(255,255,255,.4),
  inset 0 1px 2px rgba(255,255,255,.3);
  border:thin solid #232c34;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  transition: all 0.5s ease;
}
::-webkit-scrollbar-thumb:hover{
    background: -webkit-linear-gradient(top, #d9f665, #a0ba38);
}

::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(217,246,101,.6); 
}
*{
  font-size:14px;
  line-height: 1.2;
  font-weight: 500;
}
.inter{
  font-family: 'Inter', sans-serif;}
.noto{
  font-family: 'Noto Sans Display', sans-serif;
}
.font-btn{
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
}
.m-p-0{margin:0!important;
  padding:0!important;
}
.secondary-bg{
  background-color:#a0ba38;
}
.t-center{text-align: center;}
.main-menu {
  background-color:#000000;
}
.main-menu li{
  margin: auto;
}
.main-menu-link{
  /* text-transform: uppercase; */
  color: #FFFFFF!important;
  transition: all 0.5s ease;
  font-weight:500;
}
.main-menu-link:hover{
  color: #a0ba38!important;
  text-decoration: underline;
}
.menu-contact .social-contact{
  text-decoration:none;
  color: #000000;
}
.social-icon{
  color: #000000;
}
.menu-contact .social-contact:hover{
  color: #a0ba38!important;
  background-color:#000000;
  border-radius:12px;
}
.social-icon :hover{
  color: #a0ba38!important;
  background-color:#000000;
  border-radius:12px;
}
.social-icon-footer{
  color: #FFFFFF;
  margin-right: 5px;
  font-size: 20px;
}
.social-icon-fo0ter :hover{
  color: #000000;
}
.m-auto{
  margin:auto;
}
.white{
  color:#FFFFFF;
}
.img-wrapper {
  display: inline-block;
  overflow: hidden;
  border-radius: 5px;
}
.img-wrapper img {
  -webkit-transition: all .9s ease;
  -moz-transition: all .9s ease;
  -o-transition: all .9s ease;
  transition: all .9s ease;
  vertical-align: middle;
}
.img-wrapper img:hover {
  -webkit-transform:scale(1.1); /* Safari and Chrome */
  -moz-transform:scale(1.1); /* Firefox */
  -o-transform:scale(1.1); /* Opera */
  transform:scale(1.1);
 
}
.container-portfolio{
  position: relative;
  border-radius: 5px;
}
.container-portfolio:hover::before{
  opacity: 1;
  transform: perspective(400px) rotateX(1deg);
  transition: all 700ms ease 100ms;
}
.container-portfolio:before{
  position: absolute;
  content: '';
  top: 0px;
  left: 0px;
  right: auto;
  width: 100%;
  height: 50%;
  display: block;
  opacity: 0;
  text-align: center;
  transform: perspective(400px) rotateX(-90deg);
  transform-origin: top;
  transition: all 0.5s;
  background:rgba(0, 0, 0, 0.6);
  border-radius: 5px 5px 0 0;
  z-index: 1;
}
.portfolio-title {
    position: absolute;
    top: 0;
    left: 0;
    padding: 15px;
    color: #a0ba38;
    width: 100%;
    top: auto;
    bottom: 0;
    opacity: 0;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: -webkit-transform 0.4s, opacity 0.1s 0.3s;
    -moz-transition: -moz-transform 0.4s, opacity 0.1s 0.3s;
    transition: transform 0.4s, opacity 0.1s 0.3s;
    z-index: 2;
    font-family: 'Changa', sans-serif;
}
.container-portfolio:hover .portfolio-title{
  z-index: 2;
  opacity: 1;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
  transition-delay: 0.8s;
}
.container-portfolio .portfolio-title{
  -webkit-transform: translateY(10px);
  -moz-transform: translateY(10px);
  -ms-transform: translateY(10px);
  -o-transform: translateY(10px);
  transform: translateY(10px);
  transition: all 0.6s ease-in-out;
  -webkit-transition: all 0.6s ease-in-out;
  -o-transition: all 0.6s ease-in-out;
  -ms-transition: all 0.6s ease-in-out;
  -webkit-transition: all 0.6s ease-in-out;
}
.container-portfolio:hover::after {
  opacity: 1;
  transform: perspective(400px) rotateX(1deg);
  transition: all 700ms ease 300ms;
}
.container-portfolio::after{
    position: absolute;
    content: '';
    left: 0px;
    bottom: 0px;
    width: 100%;
    height: 50%;
    display: block;
    opacity: 0;
    text-align: center;
    transform: perspective(400px) rotateX(90deg);
    transform-origin: bottom;
    transition: all 0.5s;
    background:rgba(0, 0, 0, 0.6);
    border-radius: 0 0 5px 5px;
    z-index: 1;
}
/*** ------ FOOTER --------- ***/
.footer-media{
  /* text-transform: uppercase; */
  color: #FFFFFF!important;
  letter-spacing: 5px;
  transition: all 0.5s ease;
  
}
.footer-tittle{
  color:#0089E8;
  font-size: 15px;
  padding: 5px;
}
.footer-link{
  color:#FFFFFF;
  font-size:12px;
  font-weight: 500;
  text-decoration: none;
  padding: 5px;
}
.footer-botom{
  color:#FFFFFF;
  font-size: 14px;
  font-weight: 500;
}
.footer-link:hover{
  color: #a0ba38!important;
}

.p-2{padding:2px!important;}
.p-10{padding:10px;}
.p-20{padding:20px!important;}
.p-25{padding:25px;}
.p-40{padding:40px;}
.p-60{padding:60px;}
.p-0{padding:0;}
.p-10-L20{padding:10px 10px 10px 20px;}
.p-10-0{padding:10px 0;}
.m-0{margin:0;}
.centrar{display: flex!important;
  justify-content: center;
  align-items: center;}
.derecha{display: flex!important;
  justify-content: right;
  align-items: right;}
.points{
  background: linear-gradient(90deg,#fff 14px,transparent 1%) center,linear-gradient(#fff 14px,transparent 1%) center,#000000;
  background-size: 16px 16px;
  height:30px;
}
.h-100{height:100vh; width:100%;}
.bg-100{background-position:center!important;
  background-repeat:no-repeat!important;
  background-size:100% 100%!important;
}
.bg-cover{background-position:center!important;
  background-repeat:no-repeat!important;
  background-size:cover!important;
}
.title-green-bordo{color:#B4DD1F; 
  font-size:40px; 
  font-weight:800; 
  -webkit-text-stroke: .5px #000000;
  font-family: 'Changa', sans-serif;
}
.title-green{color:#B4DD1F;
  font-size:40px; 
  font-weight:800; 
  font-family: 'Changa', sans-serif;
}
.title-negro{color:#000000; 
  font-size:40px; 
  font-weight:800; 
  font-family: 'Changa', sans-serif;
}
.subtitle-negro{
  color:#000000; 
  font-size:26px; 
  font-weight:800; 
  font-family: 'Changa', sans-serif;
}
.subtitle-verde{
  color:#B4DD1F; 
  font-size:26px; 
  font-weight:800; 
  font-family: 'Changa', sans-serif;
}
.subtitle-blanco{
  color:#ffffff; 
  font-size:26px; 
  font-weight:800; 
  font-family: 'Changa', sans-serif;
}
.subtitle-texto{
  font-size:26px;
  line-height:1.1;
}
.textos{
  font-size:14px;
  line-height:1.1;
}
.f-s-12{font-size:12px}
.f-s-14{font-size:14px}
.f-s-16{font-size:16px}
.f-s-18{font-size:18px}
.f-w-800{font-weight: 800;}
.f-w-600{font-weight: 600;}
.f-w-100{font-weight: 100;}
.l-h-1{line-height:1;}
.btn-green{
  text-decoration:none;
  border-radius:15px;
  border:solid transparent;
  margin:0; 
  padding:10px 20px;
  background-color:#B4DD1F;
  color:#ffffff;
  font-size:12px;
  cursor: pointer;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
}
.btn-green:hover{
  color:#ffffff;
  background-color:#000000;
}
.btn-start{
  font-size:12px;
  font-weight:500;
  border-radius:15px;
  border:solid transparent;
  margin:0; 
  padding:0px 8px;
  background-color:#000000;
  color:#B4DD1F;
  cursor: pointer;
  position:fixed;
  z-index:5;
  right:20px;
  margin-top:50%;
  font-family: 'Ubuntu', sans-serif;
  font-weight: 400;
}
.btn-start:hover{
  color:#000000;
  background-color:#B4DD1F;
}
.sombra{
  -webkit-box-shadow: 2px 2px 9px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 2px 2px 9px 0px rgba(0,0,0,0.75);
  box-shadow: 2px 2px 9px 0px rgba(0,0,0,0.75);
}
.sombra-blanca{
  -webkit-box-shadow: 2px 2px 9px 0px rgba(255,255,255,1);
  -moz-box-shadow: 2px 2px 9px 0px rgba(255,255,255,1);
  box-shadow: 2px 2px 9px 0px rgba(255,255,255,1);
}
.section2{
  border:solid yellow;
}
.cont-item{
  display:inline-block;vertical-align:middle;
  margin:0; padding:20px 40px!important;
  width:33%!important;}
.degradado-bg-1{border-radius:15px; border:solid 1px #a19e9e!important; width:100%;
  background: rgb(246,246,247);
  background: linear-gradient(180deg, rgba(246,246,247,1) 0%, rgba(255,255,255,1) 29%, rgba(4,123,246,1) 100%);
}
.degradado-bg-2{border-radius:15px; border:solid 1px #a19e9e!important; width:100%;
  background: rgb(246,246,247);
  background: linear-gradient(180deg, rgba(246,246,247,1) 0%, rgba(255,255,255,1) 29%, rgba(246,68,4,1) 100%);
}
.degradado-bg-3{border-radius:15px; border:solid 1px #a19e9e!important; width:100%;
  background: rgb(246,246,247);
  background: linear-gradient(180deg, rgba(246,246,247,1) 0%, rgba(255,255,255,1) 29%, rgba(129,4,246,1) 100%);
}
.degradado-bg-4{border-radius:15px; border:solid 1px #a19e9e!important; width:100%;
  background: rgb(246,246,247);
  background: linear-gradient(180deg, rgba(246,246,247,1) 0%, rgba(255,255,255,1) 29%, rgba(4,246,81,1) 100%);
}
.degradado-bg-5{border-radius:15px; border:solid 1px #a19e9e!important; width:100%;
  background: rgb(246,246,247);
  background: linear-gradient(180deg, rgba(246,246,247,1) 0%, rgba(255,255,255,1) 29%, rgba(246,4,29,1) 100%);
}
.cont-subtitle{background:transparent; 
  border-radius:15px;
  padding:10px; 
  margin:0; 
  line-height: 1;
  width:100%; 
  text-align:center;
  height:160px;
}
.more{background-color:transparent;border-radius:12px;cursor:pointer;font-size:10px;font-weight:800;color:transparent;opacity:0;}
.degradado-bg-1:hover .cont-subtitle,
.degradado-bg-2:hover .cont-subtitle,
.degradado-bg-3:hover .cont-subtitle,
.degradado-bg-4:hover .cont-subtitle,
 .degradado-bg-5:hover .cont-subtitle{background:#ffffff;
  /*-webkit-box-shadow: 2px 2px 9px 0px rgba(255,255,255,1);
  -moz-box-shadow: 2px 2px 9px 0px rgba(255,255,255,1);
  box-shadow: 2px 2px 9px 0px rgba(255,255,255,1); */}
  .degradado-bg-1:hover .cont-subtitle .more,
  .degradado-bg-2:hover .cont-subtitle .more,
  .degradado-bg-3:hover .cont-subtitle .more,
  .degradado-bg-4:hover .cont-subtitle .more,
  .degradado-bg-5:hover .cont-subtitle .more{
    background-color:#B4DD1F; color:#232323;opacity:1;padding:4px 15px;
    /* -webkit-box-shadow: 2px 2px 9px 0px rgba(0,0,0,0.75);
    -moz-box-shadow: 2px 2px 9px 0px rgba(0,0,0,0.75);
    box-shadow: 2px 2px 9px 0px rgba(0,0,0,0.75); */
}
.seccion3{border:solid orange}
.sun-pay-we .info-sun-pay{margin:0; padding:10px; opacity:0; display:none;min-height: 100vh;}
.sun-pay-we:hover .info-sun-pay{background-color: #FFFFFF;opacity:1; display:block;}
#accordion .accordion-button{background-color:rgba(23, 23, 23, .2);
  border:none;
  box-shadow:none;
  color:#999999;
  font-size:14px; 
  font-weight:600; 
  /* text-transform: uppercase; */
  width:100%;
  text-decoration: none;
  padding:15px 10px;
  font-style:italic;
  transition: all 0.5s ease;
}

#accordion .accordion-button:focus{
  color:#000000; 
  font-style:normal;
  box-shadow:none!important;
}
#accordion .accordion-button:visited,
#accordion .accordion-button:hover{
  font-weight:600; 
  text-decoration: underline;
}
#accordion  .accordion-body{
  line-height: 1.2;
  font-size: 14px;
}
/* modale */
.contact-form-wrapper {margin:0; padding:20px;}
.contact-form-wrapper p {
  font-size:14px;
  color: #777;
}
.contact-form  input {
    border-radius:12px;
    height:40px;
    font-size:12px;
    margin:8px;
    color:#999;
} 
.contact-form  input:focus {
    color: #000000;
    border: solid 2px #B4DD1F;
    box-shadow: none!important;
}
.contact-form textarea {
    border-radius:12px;   
    font-size:12px;
    margin:15px 8px;
    color:#999;
}
.contact-form textarea:focus {
    color: #000000;
    border: solid 2px #B4DD1F;
    box-shadow: none!important;
}

.circle{
  border-radius:100%;
  background-color: #B4DD1F;
  height:220px;
  width:220px;
}
.circle-black{
  border-radius:100%;
  background-color: #000000;
  height:200px;
  width:200px;
}
.circle-verde{
  border-radius:100%;
  background-color: #B4DD1F;
  height:200px;
  width:200px;
}
.testimonial-body{
  min-height: 150px;
  font-size: 12px!important;
}
#circle-spinner{
  width:240px;
  height:240px;
  border:15px solid #B4DD1F;
  border-top:15px solid #000000;
  border-radius:50%;
}
#circle-spinner{
  -webkit-transition-property: -webkit-transform;
  -webkit-transition-duration: 2s;
  -webkit-animation-name: rotate;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-transition-property: -moz-transform;
  -moz-animation-name: rotate; 
  -moz-animation-duration: 2s; 
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  transition-property: transform;
  animation-name: rotate; 
  animation-duration: 2s; 
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
@-webkit-keyframes rotate {
    from {-webkit-transform: rotate(0deg);}
    to {-webkit-transform: rotate(360deg);}
}
@-moz-keyframes rotate {
    from {-moz-transform: rotate(0deg);}
    to {-moz-transform: rotate(360deg);}
}
@keyframes rotate {
    from {transform: rotate(0deg);}
    to {transform: rotate(360deg);}
}

/*pallalax*/
.parallax {
  /* The image used */
  background-image: url("../public/img/banner1.JPG");
  /* Set a specific height */
  min-height:500px; 
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.Textpalla{height:auto;}
/* cargando */
.cargando {
  background-color: #000000;
  background-image: url('../public/img/banner1.JPG');
  background-repeat:no-repeat;
  background-position: center;
  background-size: cover;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index:99;
}
.layer {
  height: 100vh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index:99;
  display: flex;
  justify-content: center;
  align-items: center; 
  text-align: center;
}

@keyframes reveal {
  from {
    clip-path: circle(0);
  }

  to {
    clip-path: circle(100vw);
  }
}

/*Efecto girar targeta*/
.carta-box {
  margin: 0;
  height: 360px;
  width: 360px;
  padding: 0;
  position: relative;
  perspective: 1000px;
  overflow: hidden;
}
.carta-box:hover .carta {
    transform: rotateY(180deg);
}
.carta {
  transform-style: preserve-3d;
  transition: all 0.5s linear;
}
.cara {
  width:100%;
  max-height:100%;
  border:solid purple;
  position: absolute;
  backface-visibility: hidden;
}
.cara.detras {
  width:100%;
  height:100%;
  transform: rotateY(180deg);
}
.servicio{border-radius:15px; margin:0; padding:0 40px 40px 40px; width: 440px!important;
  background: rgb(246,246,247);
  background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 29%, rgba(246,4,29,1) 100%);
}
.solar .get-solar{height:160px;background-color: #000000;}
.solar .get-solar img{height:100%;width:100%; transition: transform .2s; }
.solar:hover .get-solar img{transform: scale(1.1);}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.letter-image {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 200px;
	height: 200px;
	-webkit-transform: translate(-50%, -50%);
	-moz-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	cursor: pointer;
}

.animated-mail {
	position: absolute;
	height: 150px;
	width: 150px;
	-webkit-transition: .4s;
	-moz-transition: .4s;
	transition: .4s;
}
.animated-mail .body {
  position: absolute;
  bottom: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 0 100px 200px;
  border-color: transparent transparent #B4DD1F transparent;
  z-index: 2; 
}

	.top-fold {
		position: absolute;
		top: 50px;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 50px 100px 0 100px;
		-webkit-transform-origin: 50% 0%;
		-webkit-transition: transform .4s .4s, z-index .2s .4s;
		-moz-transform-origin: 50% 0%;
		-moz-transition: transform .4s .4s, z-index .2s .4s;
		transform-origin: 50% 0%;
		transition: transform .4s .4s, z-index .2s .4s;
		border-color: #c1e933 transparent transparent transparent;
		z-index: 2;
	}
	
	.back-fold {
		position: absolute;
		bottom: 0;
		width: 200px;
		height: 100px;
		background: #000000;
		z-index: 0;
	}
	
	.left-fold {
		position: absolute;
		bottom: 0;
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 50px 0 50px 100px;
		border-color: transparent transparent transparent #B4DD1F;
		z-index: 2;
	}
	
	.letter {
		left: 20px;
		bottom: 0px;
		position: absolute;
		width: 160px;
		height: 60px;
		background: white;
		z-index: 1;
		overflow: hidden;
		-webkit-transition: .4s .2s;
		-moz-transition: .4s .2s;
		transition: .4s .2s;
  }
.letter .letter-border {
			height: 10px;
			width: 100%;
      background: repeating-linear-gradient(
        -45deg,
        #000000,
        #000000 8px,
        transparent 8px,
        transparent 18px
      );
		}
		
.letter .letter-title {
			margin-top: 10px;
			margin-left: 5px;
			height: 10px;
			width: 40%;
			background: #000000;
}
.letter .letter-context {
			margin-top: 10px;
			margin-left: 5px;
			height: 10px;
			width: 20%;
			background: #000000;
}
.letter .letter-stamp {
			margin-top: 30px;
			margin-left: 120px;
			border-radius: 100%;
			height: 30px;
			width: 30px;
			background: #000000;
			opacity: 0.3;
}

.shadow {
	position: absolute;
	top: 200px;
	left: 50%;
	width: 400px;
	height: 30px;
	transition: .4s;
	transform: translateX(-50%);
	-webkit-transition: .4s;
	-webkit-transform: translateX(-50%);
	-moz-transition: .4s;
	-moz-transform: translateX(-50%);
	
	border-radius: 100%;
	background: radial-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.0), rgba(0,0,0,0.0));
}

	.letter-image:hover .animated-mail {
			transform: translateY(50px);
			-webkit-transform: translateY(50px);
			-moz-transform: translateY(50px);
		}
		
    .letter-image:hover .animated-mail .top-fold {
			transition: transform .4s, z-index .2s;
			transform: rotateX(180deg);
			-webkit-transition: transform .4s, z-index .2s;
			-webkit-transform: rotateX(180deg);
			-moz-transition: transform .4s, z-index .2s;
			-moz-transform: rotateX(180deg);
			z-index: 0;
		}
		
    .letter-image:hover .animated-mail .letter {
			height: 180px;
		}
		
    .letter-image:hover .shadow {
			width: 250px;
		}

.collage{
  background-color: #FFFFFF;
      display: grid;
      grid-gap: 10px;
      grid-template-columns: auto auto auto auto auto auto;
      padding: 10px;
      width:100%;
  }
  
  .collage span{
      box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0;
  }
  .collage span{
    padding: 66px;
    border:solid #ffffff 1px;
  }
  .collage span button{
    background-color:transparent;
    border:solid transparent 1px;
    padding:10px 20px;
    border-radius: 12px;
    color:transparent;
  }
  .collage span:hover{
    -webkit-box-shadow: 0px 0px 5px 5px rgba(180,221,31,0.87);
    -moz-box-shadow: 0px 0px 5px 5px rgba(180,221,31,0.87);
    box-shadow: 0px 0px 5px 5px rgba(180,221,31,0.87);
  }
  .collage span:hover button{
    background-color:#B4DD1F;
    border:solid transparent 1px;
    padding:10px 20px;
    border-radius: 12px;
    color:#000000
  }
  .collage span:nth-of-type(1){
      grid-area: 1 / 1 / span 6 / span 6;
  }
  .collage span:nth-of-type(2){
      grid-area: 7 / 1 / span 1 / span 2;
  }
  .collage span:nth-of-type(3){
      grid-area: 7 / 3 / span 1 / span 2;
  }
  .collage span:nth-of-type(4){
      grid-area: 8 / 1 / span 2 / span 4;
  }
  .collage span:nth-of-type(5){
      grid-area: 7 / 5 / span 3 / span 2;
  }

  @media only screen and (max-width: 1000px) {
    .collage span{
      padding: 30px;
    }
  }

  #arrowAnim {
    width: auto;
    height:auto;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index:1;

  }
  
  .arrow {border:solid orange;
   
    width: 2vw;
    height: 2vw;
    border: 1vw solid;
    border-color: #B4DD1F transparent transparent #B4DD1F;
    transform: rotate(135deg);
  }
  
  
  .arrowSliding {
    position: absolute;
    -webkit-animation: slide 3s linear infinite; 
            animation: slide 3s linear infinite;
  }
  
  .delay1 {
    -webkit-animation-delay: 1s; 
      animation-delay: 1s;
  }
  .delay2 {
    -webkit-animation-delay: 2s; 
      animation-delay: 2s;
  }
  .delay3 {
    -webkit-animation-delay: 3s; 
      animation-delay: 3s;
  }
  
  @-webkit-keyframes slide {
      0% { opacity:0; transform: translateX(-15vw); }	
     20% { opacity:1; transform: translateX(-9vw); }	
     80% { opacity:1; transform: translateX(9vw); }	
    100% { opacity:0; transform: translateX(15vw); }	
  }
  @keyframes slide {
      0% { opacity:0; transform: translateX(-15vw); }	
     20% { opacity:1; transform: translateX(-9vw); }	
     80% { opacity:1; transform: translateX(9vw); }	
    100% { opacity:0; transform: translateX(15vw); }	
  }

  /****** Adrian CSS *******/
  .about-us-text ,strong{
    font-size: 20px;
    text-align: justify;
    text-justify: inter-word!important;
    font-weight: 400;
    padding: 35px 5px;
    margin-top: 30px!important;
  }
  
 